<template>
  <div>
    <div v-if="this.company" class="menu-items">
      <a
       href="/support/search"
        class="cardTransactions cursor-pointer"
        :class="this.menuSelected.search && 'cardTransactions-active'"
        >{{ $t("searchBeneficiaryName") }}</a
      >
      <a
      href="/support/beneficiary"
      class="contact cursor-pointer"
        :class="this.menuSelected.beneficiary && 'contact-active'"
        >{{ $t("beneficiarySupportName") }}</a
      >
      <FlexbenMenu v-if="showFlxMenu" :menu-selected="menuSelected" />
      <a
      href="/support/account"
      class="amanage cursor-pointer"
        :class="this.menuSelected.account && 'amanage-active'"
        >{{ $t("settingsName") }}</a
      >
      <a href="#" class="logout" @click="logout()">{{ $t("logoutName") }}</a>
    </div>
    <div class="socials">
      <a href="https://www.facebook.com/UpRomania" class="facebook"></a>
      <a
        href="https://www.linkedin.com/company/up-romania"
        class="linkedin"
      ></a>
      <a
        href="https://www.youtube.com/c/UpRomaniaBucuresti"
        class="youtube"
      ></a>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import FlexbenMenu from "@/components/fragments/FlexbenMenu";

export default {
  name: "MenuItemsSupportBeneficiary",
  props: {
    company: Object,
    menuSelected: {
      search: false,
      beneficiary: false,
      account: false,
      flexben: false,
    },
    user: Object
  },
  components: {
    FlexbenMenu,
  },
  data: function () {
    return { showFlxMenu: false};
  },
  methods: {
    logout() {
      Cookies.remove("selectedBeneficiary");
      Cookies.remove("selectedCompany");
      Cookies.remove("userLink");
      Cookies.remove("user_role");
      Cookies.remove("token");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
      this.$emit("sendUserRole", "", "");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    }
  },
  mounted() {
    if (sessionStorage.getItem('flx_active_employment') || this.flxUserLevel().userLevel==='admin') {
      this.showFlxMenu = true;
    }
  },
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
