<template>
  <div>
    <table
      cellpadding="10"
      cellspacing="0"
      width="95%"
      class="hover-table invoices-table"
      align="center"
    >
      <thead>
        <tr>
          <td valign="middle">
            <strong>{{ $t("accountChannelLabel") }}</strong>
          </td>
          <td valign="middle">
            <strong>{{ $t("usernameLabel") }}</strong>
          </td>
          <td valign="middle">
            <strong>{{ $t("beneficiariesTableDateCreated") }}</strong>
          </td>
          <td  valign="middle">
            <strong>{{ $t("ordersTableHeaderStatus") }}</strong>
          </td>
          <td cvalign="middle">
            <!-- <strong>{{ $t("valueLabel") }}</strong> -->
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.accounts === null">
          <td colspan="6">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(account, index) in this.accounts">
          <tr
            :key="index"
            class="trhover parent"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            <td valign="middle">
              {{ account.channel }}
            </td>
            <td valign="middle">
              {{ account.username }}
            </td>
            <td valign="middle" >
              {{ account.created }}
            </td>
            <td valign="middle">
              {{ account.status }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
  import moment from "moment";

  export default {
    name: "BeneficiaryAccountsTable",
    props: {
      accounts: Array,
      msg: String,
    },
    data() {
      return {
        transactionError: null,
      };
    },
    methods: {
      displayDate(date) {
        return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format(
          "DD/MM/YYYY HH:mm"
        );
      },
    },
  };
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
