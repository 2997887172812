import axios from "axios";

let affiliateSvc = process.env.VUE_APP_COMPANY_API_URL;
let companyRoot = process.env.VUE_APP_COMPANY_USER_ROOT;

axios.defaults.headers.common["api_key"] =
	process.env.VUE_APP_CARD_USER_API_KEY;

const supportAxiosinstance = axios.create({
	baseURL: affiliateSvc,
});

export default {
	beneficiariesService: {
		searchBeneficiaries(beneficiariesFilter, token) {
			return supportAxiosinstance.post(
				companyRoot +
				"/v1/plus/users/search",
				beneficiariesFilter,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getBeneficiaryDetails(beneficiaryId, token) {
			return supportAxiosinstance.get(
				companyRoot +
				"/v1/plus/users/" + beneficiaryId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		deleteMobileAccount(beneficiaryId, token) {
			return supportAxiosinstance.delete(
				companyRoot +
				"/v1/plus/users/" +
				beneficiaryId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		updateMobileAccount(beneficiaryId, mobileData, token) {
			return supportAxiosinstance.put(
				companyRoot +
				"/v1/plus/users/" +
				beneficiaryId,
				mobileData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
	},
};
