<template>

  <div >
    <div class="beneficary-details-page">
      <div class="left">
        <h1>
        
          {{ this.beneficiary.last_name + " " + this.beneficiary.first_name }}
        </h1>
      </div>
      <div class="right">
        
      </div>
      <br />
      <br />
      <div :class="!this.beneficiary.active && 'benef'">
        <div class="container-alb">
          <table width="95%" align="center" cellpadding="10" cellspacing="0">
            <tr>
              <td align="left">
                <small>{{ $t("lastNameField") }}</small>
                <br />
                <input
                  id="beneficiaryLastName"
                  type="text"
                  v-model="formData.lastName"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
              <td align="left">
                <small>{{ $t("surnameFieldLabel") }}</small>
                <br />
                <input
                  type="text"
                  id="beneficiaryFirstName"
                  v-model="formData.firstName"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
              <td align="left">
                <small>{{ $t("cnpFieldLabel") }}</small>
                <br />
                <input
                  type="text"
                  :value="beneficiary.cnp"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
            </tr>

            <!-- <tr>
              <td align="left">
                <small>{{ $t("mobileField") }}</small>
                <br />
                <input
                  type="text"
                  id="beneficiaryMobilePhone"
                  v-model="formData.mobilePhone"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
              <td align="left">
                <small>{{ $t("emailField") }}</small>
                <br />
                <input
                  type="text"
                  id="beneficiaryEmail"
                  v-model="formData.email"
                  class="formedituser"
                  disabled
                />
              </td>
            </tr> -->
          </table>

        </div>
        <div class="loading" v-if="this.beneficiaryPlus === null">
          <img src="@/assets/images/loading.gif" />
        </div>
        <div v-else class="container-alb" >
          <div style="margin-left: 30px;">
          <h3>{{ $t('activeModulesPlus') }}</h3>
          <template v-for="(module, name) in this.beneficiary.modules">
                {{ (module ? name : "") + " / " }}
              </template>
              <h3>{{ $t('activeAccountLoginsPlus') }}</h3>
            </div>
          <BeneficiaryAccountsTable
          :accounts="this.beneficiaryPlus.accounts"
          @change="sortBy(sortField, sortDirection)"
          />
          <br />
          <table cellpadding="10" cellspacing="0" width="95%" align="center">
            <tr>
              <td>
                <a
                        class="notification-action custom-notification-action"
                        @click="
                          deleteMobileAccount()
                          "
                        >{{ $t("deleteAccountLabel") }}</a
                      >
              </td>
            </tr> 
          </table>
        </div>
        <div class="loading" v-if="this.beneficiaryCoreAccounts === null">
          <img src="@/assets/images/loading.gif" />
        </div>
        <div v-else
          v-for="(coreAccount, index) in this.beneficiary.core_data"
          :key="index" 
        >
        <template v-if="beneficiaryCoreAccounts[coreAccount.codhd]">
          <div
          v-for="(entry, index) in beneficiaryCoreAccounts[coreAccount.codhd].accounts"
          
          :key="index"
          class="box-left "
          :class="index % 2 === 1 && 'clear-none'"
          >
          <div class="container-alb">
            <div class="usage-volume-container">
              <img
              :src="require('@/assets/images/logo-up-' +
                (entry.product > 1500 ? '15' : entry.product) +
                '.png')
                "
                />
                <br />
                <div v-if="entry.card && entry.card !== null">
                  <div class="grey-card">
                    <p align="center">
                      {{ $t("searchByClientCode") + ": " + coreAccount.codhd }}
                      <br />
                      {{ $t("orderExcelColumn_8") + ": " + entry.card.addressCode }}
                      <br />
                      {{ $t("cardNumberLabel") }}
                      {{ entry.card && entry.card.pan }}
                      <br />
                      {{ $t("beneficiariesTableCardStatus") + ": " }}
                      {{ $t("cardStatus_" + entry.card.state) }}
                      <br />
                      {{ $t("beneficiariesTableCardType") + ": " }}
                      {{ entry.card.virtual ? $t('beneficiariesTableCardTypeVirtual') : $t('beneficiariesTableCardTypePhysical') }}
                      <br />
                      {{
                        entry.card &&
                        $t("cardExpiryLabel") + " " + entry.card.expire
                      }}
                      <!-- {{
                          $t("currentBalanceLabel") +
                            " " +
                            entry.spent +
                            " " +
                            $t("currency")
                      }} -->
                    </p>
                    <div class="text-center">
                      <template v-for="(event, index) in entry.card.events">
                        <span :key="index" class="status-background" :class="getClassForCardStatus(event.type)">{{
                          $t("cardStatus_" + event.type) }}</span>
                        </template>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
        <!-- <div >
          <div class="modal-popup-content">
            <img src="@/assets/images/loading.gif" />
            <p v-html="$t('loadingPopupMessage')"></p>
          </div>
        </div> -->
        <!-- <div class="container-alb scroll-table">
          <table cellpadding="10" cellspacing="0" width="95%" align="center">
            <tr>
              <td>
                <h3>{{ $t("beneficiaryOrderHistory") }}</h3>
              </td>
            </tr>
          </table> 
 
          <table
            v-if="this.historyDetails !== null"
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table"
            align="center"
          >
            <tr>
              <td @click="() => (sortBy = 'date')" valign="middle">
                <strong>{{ $t("ordersTableHeaderDate") }}</strong
                >&nbsp;
                <img
                  src="@/assets/images/arrow-down-fill.svg"
                  @click="sortBy === 'date' && changeReverse()"
                  :style="
                    sortBy === 'date' && reverse && 'transform: rotate(180deg)'
                  "
                />
              </td>
              <td @click="() => (sortBy = 'product')" valign="middle">
                <strong>{{ $t("ordersTableHeaderProduct") }}</strong
                >&nbsp;
                <img
                  src="@/assets/images/arrow-down-fill.svg"
                  @click="sortBy === 'product' && changeReverse()"
                  :style="
                    sortBy === 'product' &&
                    reverse &&
                    'transform: rotate(180deg)'
                  "
                />
              </td>
              <td
                @click="() => (sortBy = 'order_type')"
                class="priority-3"
                valign="middle"
              >
                <strong>{{ $t("ordersTableHeaderType") }}</strong
                >&nbsp;
                <img
                  src="@/assets/images/arrow-down-fill.svg"
                  @click="sortBy === 'order_type' && changeReverse()"
                  :style="
                    sortBy === 'order_type' &&
                    reverse &&
                    'transform: rotate(180deg)'
                  "
                />
              </td>
              <td class="priority-4" valign="middle">
                <strong>{{ $t("ordersTableHeaderAmount") }}</strong>
              </td>
              <td class="priority-5" valign="middle">
                <strong>{{ $t("ordersTableHeaderNumber") }}</strong>
              </td>
            </tr>
            <template v-for="(entry, index) in this.filterHistory">
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
                @click="selectHistoryDetailsMobile(index)"
              >
                <td>
                  <a
                    class="detailsOrderMobile priority-show-5 display-inline-block"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && entry === selectedHistoryDetails"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  {{ displayDate(entry.date) }}
                </td>
                <td>
                  <img
                    :src="
                      require('@/assets/images/bullet_' +
                      (entry.product > 1500 ? '15' : entry.product)+

                        '.svg')
                    "
                  />
                  &nbsp;&nbsp;{{ getProductNameMethod(entry.product) }}
                </td>
                <td class="priority-3">
                  {{ $t("orderType_" + entry.order_type) }}
                </td>
                <td class="priority-4">{{ entry.description }}</td>
                <td class="priority-5">
                  <a
                    class="see-more color-white"
                    :href="'/customer/orders?order_id=' + entry.order_id"
                    >{{ $t("beneficiariesTableSeeMore") }}</a
                  >
                </td>
              </tr>
              <tr
                :key="index + filterHistory.length"
                class="child"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && entry === selectedHistoryDetails"
              >
                <td colspan="12">
                  <p class="priority-show-3">
                    <strong
                      >{{ $t("ordersTableHeaderType") }} : &nbsp;&nbsp;</strong
                    >
                    <span>{{ $t("orderType_" + entry.order_type) }}</span>
                  </p>
                  <p class="priority-show-4">
                    <strong>
                      {{ $t("ordersTableHeaderAmount") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ entry.description }}</span>
                  </p>
                  <p class="priority-show-5">
                    <strong>ID : &nbsp;&nbsp;</strong>
                    <a
                      class="see-more color-white"
                      :href="'/customer/orders?order_id=' + entry.order_id"
                      >{{ $t("beneficiariesTableSeeMore") }}</a
                    >
                  </p>
                </td>
              </tr>
            </template>
          </table> 
        </div> -->
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="
            refresh ? redirectToSearchPage() : showModalPopup('modal_popup_info', false)
            "
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p
          v-if="this.actionError"
          v-html="$t('errorActionBeneficiary_' + this.actionError.code)"
        ></p>
        <p v-else>{{ this.successMessage }}</p>

        <!-- <div v-if="this.actionOrderErrors">
          <p
            v-for="(error, index) in this.actionOrderErrors"
            :key="index"
            class="trhover"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            {{ $t(error.error_code, { name: error.from_source_id }) }}
          </p>
        </div> -->
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
  import httpServiceBenef from "@/http/http-ben-support.js";
  import httpServiceCustomer from "@/http/http-customer.js";
  import utils from "@/plugins/utils.js";
  import moment from "moment";
  import Cookies from "js-cookie";
  import $ from "jquery";
  import BeneficiaryAccountsTable from "@/components/company/beneficiaries-support/BeneficiaryAccountsTable.vue";
  // import CustomerBeneficiaryDetails from "@/components/company/customer/BeneficiaryDetails.vue";

  export default {
    props: {
      beneficiaryId: Number,
      user: Object,
    },
    components: {
      BeneficiaryAccountsTable,
    },
    data() {
      return {
        beneficiary: null,
        beneficiaryPlus: null,
        beneficiaryCoreAccounts: null,
        historyDetails: null,
        choosenAccount: null,
        beneficiaryDataError: null,
        actionError: null,
        successMessage: null,
        formData: null,
        editMode: false,
        detailsMobile: false,
        selectedHistoryDetails: null,
        search: "",
        sortBy: "date",
        reverse: true,
        refresh: false,
        config: null,
      };
    },
    methods: {
      getBeneficiaryDetails() {
        if (this.beneficiary.up_uuid) {
          this.getBeneficiaryDetailsPlus();
        } else {
          this.beneficiaryPlus = {};
        }
        if (this.beneficiary.core_data) {
          for (let index in this.beneficiary.core_data) {
            this.getBeneficiaryDetailsCore(this.beneficiary.core_data[index])
          }
        } else {
          this.beneficiaryCoreAccounts = []
        }
      },
      getBeneficiaryDetailsPlus() {
        httpServiceBenef.beneficiariesService
          .getBeneficiaryDetails(
            this.beneficiary.up_uuid,
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            this.showModalPopup("modal_popup_loading", false, null, this.action);
          })
          .then((response) => {
            if (response.status == 200) {
              this.beneficiaryPlus = response.data;
              /* this.beneficiary.accounts[0].card.state = 'A';
              this.beneficiary.accounts[0].card.virtual = true;
  */
              this.resetBeneficiaryDetails();
              this.$forceUpdate();
              this.showModalPopup("modal_popup_loading", false, null, this.action);
            } else if (response.status == 400 || response.status == 403) {
              this.beneficiaryPlus = null;
            }
          });
      },
      getBeneficiaryDetailsCore(coreData) {
        httpServiceCustomer.customerService
          .getBeneficiaryDetails(
            coreData.codhd,
            coreData.holder_id,
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            this.showModalPopup("modal_popup_loading", false, null, this.action);
          })
          .then((response) => {
            if (response.status == 200) {
              if (this.beneficiaryCoreAccounts === null) {
                this.beneficiaryCoreAccounts = [];
              }
              this.beneficiaryCoreAccounts[coreData.codhd] = response.data;
            } else if (response.status == 400 || response.status == 403) {
            }
          });
      },
      deleteMobileAccount() {
        this.showModalPopup("modal_popup_loading", true);
        this.actionError = null;
        httpServiceBenef.beneficiariesService
          .deleteMobileAccount(
            this.beneficiary.up_uuid,
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.actionError = null;
              this.successMessage = this.$t("deleteMobileAccountSucceess");
              this.refresh = true;
              // this.$emit("personChanged");
              // window.location.reload();
            } else if (response.status == 400) {
              this.error = { code: response.code };
            } else {
              this.error = { code: response.status };
            }
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          });
      },
      updateMobileTransactions() {
        this.showModalPopup("modal_popup_loading", true);
        this.actionError = null;
        httpServiceBenef.beneficiariesService
          .updateMobileAccount(
            this.beneficiary.up_uuid,
            {
              mobile_data: 'mockup'
            },
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.actionError = null;
              this.successMessage = this.$t("updateMobileAccountSucceess");
              // this.$emit("personChanged");
              // window.location.reload();
            } else if (response.status == 400) {
              this.error = { code: response.code };
            } else {
              this.error = { code: response.status };
            }
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          });
      },
      getBeneficiaryHistory() {
        httpServiceCustomer.customerService
          .getBeneficiaryHistory(
            this.customer.company_id,
            this.beneficiaryId,
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            this.showModalPopup("modal_popup_loading", false, null, this.action);
            this.historyDetails = null;
          })
          .then((response) => {
            if (response.status == 200) {
              this.historyDetails = response.data;
            } else {
              this.historyDetails = null;
            }
          });
      },
      getProductNameMethod(productId) {
        return utils.getCompanyProductName(productId, this.customer.products);
      },
      displayDate(date) {
        return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
      },
      showModalPopup(
        elementId,
        show,
      ) {
        if (show) {
          document.getElementById(elementId).style.visibility = "visible";
        } else {
          document.getElementById(elementId).style.visibility = "hidden";
        }
      },
      getResponseErrors(response) {
        let errorsLink = response.headers["link"].split(";")[0];
        errorsLink = errorsLink.replace(">", "");
        errorsLink = errorsLink.replace("<", "");
        if (errorsLink.includes("exceptions_type")) {
          httpServiceCustomer.customerService
            .getOrderErros(errorsLink, this.accessToken)
            .then((response) => {
              if (response.status === 200) {
                this.actionOrderErrors = response.data.errors;
                this.error = { code: "ERROR" };
              }
            })
            .catch((error) => {
              if (error.response.data) {
                this.error = { code: error.response.data.code };
              } else if (error.response.status === 409) {
                this.error = { code: "409" };
              } else {
                this.error = { code: "500" };
              }
            });
        }
        this.showModalPopup("modal_popup_loading", false, null, this.action);
        this.showModalPopup(
          "modal_popup_confirmCardRequest",
          false,
          null,
          this.action
        );
        this.showModalPopup('modal_popup_virtual_details', false, null, this.action);
        this.showModalPopup("modal_popup_info", true, null, this.action);
      },
      editBeneficiaryDetails() {
        this.editMode = true;
        this.action = "MODIFY_DATA";
        $("#beneficiaryFirstName").prop("disabled", false);
        $("#beneficiaryLastName").prop("disabled", false);
        $("#beneficiaryMobilePhone").prop("disabled", false);
        $("#beneficiaryEmail").prop("disabled", false);
      },
      deleteBeneficiary() {
        this.showModalPopup("modal_popup_loading", true, null, this.action);
        this.actionError = null;
        let beneficiaryData = this.beneficiary;
        beneficiaryData.mobile_phone = this.formData.mobilePhone;
        beneficiaryData.email = this.formData.email;
        httpServiceCustomer.customerService
          .deleteBeneficiary(
            this.customer.company_id,
            this.beneficiary.up_uuid,
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            this.actionError = { code: error.response.data.code };
            this.beneficiaryDataError = { code: error.response.code };
            this.showModalPopup("modal_popup_loading", false, null, this.action);
            this.showModalPopup("modal_popup_info", true, null, this.action);
          })
          .then((response) => {
            if (response.status == 200) {
              this.beneficiary = response.data;
              this.actionError = null;
              this.successMessage = this.$t("deleteDataSucceess");
              this.showModalPopup(
                "modal_popup_loading",
                false,
                null,
                this.action
              );
              this.showModalPopup("modal_popup_info", true, null, this.action);
              this.$emit("personChanged");
              // window.location.reload();
            } else if (response.status == 400) {
              this.beneficiaryDataError = { code: response.code };
            } else {
              this.beneficiaryDataError = { code: response.code };
            }
          });
      },
      submitBeneficiaryDetails() {
        this.showModalPopup("modal_popup_loading", true, null, this.action);
        this.actionError = null;
        let beneficiaryData = this.beneficiary;
        beneficiaryData.mobile_phone = this.formData.mobilePhone;
        beneficiaryData.email = this.formData.email;
        httpServiceCustomer.customerService
          .modifyBeneficiaryData(
            this.customer.company_id,
            {
              up_uuid: this.beneficiary.up_uuid,
              last_name: this.formData.lastName,
              first_name: this.formData.firstName,
              mobile_phone: this.formData.mobilePhone,
              email: this.formData.email,
              active:
                this.action === "INACTIVATE"
                  ? false
                  : this.action === "ACTIVATE"
                    ? true
                    : this.beneficiary.active
            },
            this.accessToken
          )
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.beneficiary = response.data;
              this.actionError = null;
              this.successMessage = this.$t("modifiyDataSucceess");
              this.editMode = false;
              this.resetBeneficiaryDetails();
              this.showModalPopup(
                "modal_popup_loading",
                false,
                null,
                this.action
              );
              // this.showModalPopup("modal_popup_info", true, null, this.action);
              this.$emit("personChanged");

              // window.location.reload();
            } else if (response.status == 400) {
              console.log(response);
              this.beneficiaryDataError = { code: response.data.error_code };
            } else {
              this.beneficiaryDataError = { code: response.data.error_code };
            }

            // this.actionError = { code: error.response.data.code };
            // this.beneficiaryDataError = { code: error.response.code };
            this.showModalPopup("modal_popup_loading", false, null, this.action);
            this.showModalPopup("modal_popup_info", true, null, this.action);
          });
      },
      resetBeneficiaryDetails() {
        this.formData = {
          lastName: this.beneficiary.last_name,
          firstName: this.beneficiary.first_name,
          cnp: this.beneficiary.cnp,
          // mobilePhone: this.beneficiary.mobile_phone,
          // email: this.beneficiary.email
        };
        this.editMode = false;
        $("#beneficiaryFirstName").prop("disabled", true);
        $("#beneficiaryLastName").prop("disabled", true);
        $("#beneficiaryMobilePhone").prop("disabled", true);
        $("#beneficiaryEmail").prop("disabled", true);
      },
      selectHistoryDetailsMobile(index) {
        this.selectedHistoryDetails = this.filterHistory[index];
        this.detailsMobile = !this.detailsMobile;
      },
      changeReverse() {
        this.reverse = !this.reverse;
      },
      getValueForSort(entry) {
        switch (this.sortBy) {
          case "product":
            return this.getProductNameMethod(entry[this.sortBy]);
          case "order_type":
            return this.$t("orderType_" + entry.order_type);
          default:
            return entry[this.sortBy];
        }
      },
      onlyAlphabetCharacters(variable) {
        if (/^[a-zA-Z-]*$/i.test(variable)) {
          return variable;
        } else {
          return variable.replace(/[0-9&\/\\|`#@\[\]\,+=()$~%.'":*!?<>{}]/g, "");
        }
      },
      onlyNumericCharacters(variable) {
        if (/^[0-9]*$/i.test(variable)) {
          return variable;
        } else {
          return variable.replace(
            /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
            ""
          );
        }
        return variable;
      },
      updateInputCharacters() {
        this.formData.lastName = this.onlyAlphabetCharacters(
          this.formData.lastName
        );
        this.formData.firstName = this.onlyAlphabetCharacters(
          this.formData.firstName
        );
        this.formData.mobilePhone = this.onlyNumericCharacters(
          this.formData.mobilePhone
        );
        this.beneficiary.security_code = this.onlyNumericCharacters(
          this.beneficiary.security_code
        );
        this.$forceUpdate();
      },
      refreshPage() {
        this.beneficiary = null;
        this.getBeneficiaryDetails();
      },
      redirectToSearchPage() {
        this.pushRoute('support_beneficiaries_search');
      },
      getClassForCardStatus(status) {
        switch (status) {
          case "A":
            return "background-active";
          case "B":
          case "F":
          case "RC":
            return "background-blocked";
          case "D":
            return "background-deactivated";
          case "C":
          case "X":
          case "RNN":
            return "background-inactive";
          case "J":
          case "L":
            return "background-cancelled";
          case "E":
          case "I":
          case "M":
          case "N":
          case "O":
          case "P":
          case "R":
          case "S":
          case "RN":
          case "RNV":
          case "RNY":
          case "RP":
            return "background-will-expire";

          default:
            return "background-active";
        }
      },
    },
    computed: {
      filterHistory: function () {
        var returnHistoryDetails = this.historyDetails;
        const result = returnHistoryDetails.sort((a, b) =>
          this.getValueForSort(a) < this.getValueForSort(b)
            ? -1
            : a[this.sortBy] !== b[this.sortBy]
        );
        return this.reverse ? result.reverse() : result;
      },
    },
    created() {
      this.accessToken = Cookies.get("token");
      this.beneficiary = JSON.parse(localStorage.getItem("selected_beneficiary"));

      this.getBeneficiaryDetails();
      // this.getBeneficiaryHistory();

    },
    mounted() {
      this.config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));
    }
  };
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
  .clear-none {
    clear: none;
  }

  .beneficary-details-page .custom-notification-action {
    display: inline-block;
    width: auto;
  }

  .beneficary-details-page .left {
    display: inline-block;
    width: 50%;
  }

  .beneficary-details-page .right {
    display: inline-block;
    width: 50%;
  }

  .beneficary-details-page .right .notification-action {
    margin-left: 10px;
  }

  .resetButton:not([href]):not([tabindex]) {
    text-decoration: underline;
    color: #585858;
    font-style: italic;
    cursor: pointer;
  }

  .benef {
    position: relative;
    z-index: 9;
  }

  .disabled {
    background-color: #9b9b9b !important;
  }

  @media (max-width: 1003px) {
    .beneficary-details-page {
      width: 95%;
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    .beneficary-details-page .custom-notification-action {
      display: inline-block;
      width: auto !important;
      padding: 10px;
      background-image: none;
    }
  }
</style>
