<template>
  <div>
    <table
      cellpadding="10"
      cellspacing="0"
      width="95%"
      class="hover-table invoices-table"
      align="center"
    >
      <thead>
        <tr>
          <td valign="middle">
            <strong>{{ $t("beneficiariesTableCNP") }}</strong>
          </td>
          <td valign="middle">
            <strong>{{ $t("firstNameLabel") }}</strong>
          </td>
          <td valign="middle">
            <strong>{{ $t("lastNameLabel") }}</strong>
          </td>
          <td valign="middle">
            <strong>{{ $t("beneficiariesTableDateCreated") }}</strong>
          </td>
          <td  valign="middle">
            <strong>{{ $t("beneficiariesTableModules") }}</strong>
          </td>          
          <td  valign="middle">
            <strong>{{ $t("beneficiariesTableStatus") }}</strong>
          </td>
          <td cvalign="middle">
            <!-- <strong>{{ $t("valueLabel") }}</strong> -->
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.beneficiaries === null">
          <td colspan="6">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(beneficiary, index) in beneficiaries">
          <tr
            :key="index"
            class="trhover parent"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            <td valign="middle">
              <a
                class="detailsOrderMobile priority-show-6 display-inline-block"
                @click="setBeneficiary(beneficiary)"
              >
                <font-awesome-icon
                  icon="minus-circle"
                  v-if="
                    detailsMobile && beneficiary === beneficiarySelectedMobile
                  "
                />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              {{ beneficiary.cnp }}
            </td>
            <td valign="middle">
              {{ beneficiary.first_name }}
            </td>
            <td valign="priority-1">
              {{ beneficiary.last_name }}
            </td>
            <td valign="middle" class="priority-1">
              {{ beneficiary.created }}
            </td>
            <td valign="middle" class="priority-1">
              <template v-for="(module, name) in beneficiary.modules">
                {{ (module ?  name : "") + " / " }}
              </template>
            </td>
            <td valign="middle" class="priority-4">
              {{ $t('cardStatus_' + beneficiary.status) }}
            </td>
            <td valign="middle">
                  <a
                    class="see-more cursor-pointer"
                    @click="setBeneficiary(beneficiary)"
                  >
                    <font-awesome-icon
                      icon="arrow-alt-circle-right"
                      size="2x"
                    />
                  </a>
                </td>
          </tr>
          <tr
            :key="index + beneficiaries.length"
            class="child"
            :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && beneficiary === beneficiarySelectedMobile"
          >
            <td colspan="12">
              <p class="priority-show-1">
                <strong>{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.cnp }}</span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("firstNameLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.first_name }} </span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("lastNameLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.last_name }} </span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("beneficiariesTableDateCreated") }} : &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.created }}</span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("beneficiariesTableModules") }} : &nbsp;&nbsp;</strong>
                <template v-for="(module, name) in beneficiary.modules">
                {{ (module ?  name : "") + " / " }}
              </template>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("beneficiariesTableStatus") }} : &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.status }}</span>
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
  import moment from "moment";
  import Cookies from "js-cookie";

  export default {
    name: "CardTransactionTable",
    props: {
      beneficiaries: Array,
      msg: String,
    },
    data() {
      return {
        detailsMobile: false,
        beneficiarySelectedMobile: null,
        transactionError: null,
      };
    },
    methods: {
      displayDate(date) {
        return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format(
          "DD/MM/YYYY HH:mm"
        );
      },
      selectTransactionMobile(index) {
        this.beneficiarySelectedMobile = this.beneficiaries[index];
        this.detailsMobile = !this.detailsMobile;
      },
      showModalPopup(elementId, show) {
        if (show) {
          document.getElementById(elementId).style.visibility = "visible";
        } else {
          document.getElementById(elementId).style.visibility = "hidden";
        }
      },
      setBeneficiary(beneficiary) {
        Cookies.set(
          "selectedBeneficiary",
          beneficiary.up_uuid,
          {
            sameSite: "Strict"
          }
        );
        localStorage.setItem("selected_beneficiary", JSON.stringify(beneficiary));
        // localStorage.removeItem("customer_data");
        // setTimeout(()=>{
        //       this.$router.push({ name: "company" });
        //   //    window.location = '/company';
        // },500)
        this.pushRoute('support_beneficiary');
      },
    },
    mounted(){
      localStorage.removeItem("selected_beneficiary");
    }
  };
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
