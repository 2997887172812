var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-alb filter-mobile"},[_c('table',{staticClass:"hover-table",attrs:{"cellpadding":"10","cellspacing":"0","width":"95%","align":"center"}},[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("beneficiariesTableCNP"))+" "),_c('form',{on:{"submit":function($event){$event.preventDefault();{
              return false;
            }}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.cnp),expression:"filters.cnp"}],staticClass:"formedituser",attrs:{"type":"text"},domProps:{"value":(_vm.filters.cnp)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBeneficiaries()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "cnp", $event.target.value)}}})])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t("lastNameLabel"))+" "),_c('form',{on:{"submit":function($event){$event.preventDefault();{
              return false;
            }}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.last_name),expression:"filters.last_name"}],staticClass:"formedituser",attrs:{"type":"text"},domProps:{"value":(_vm.filters.last_name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBeneficiaries()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "last_name", $event.target.value)}}})])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t("firstNameLabel"))+" "),_c('form',{on:{"submit":function($event){$event.preventDefault();{
              return false;
            }}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.first_name),expression:"filters.first_name"}],staticClass:"formedituser",attrs:{"type":"text"},domProps:{"value":(_vm.filters.first_name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBeneficiaries()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "first_name", $event.target.value)}}})])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("emailField"))+" "),_c('form',{on:{"submit":function($event){$event.preventDefault();{
              return false;
            }}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.email),expression:"filters.email"}],staticClass:"formedituser",attrs:{"type":"text"},domProps:{"value":(_vm.filters.email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBeneficiaries()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "email", $event.target.value)}}})])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t("mobileField"))+" "),_c('form',{on:{"submit":function($event){$event.preventDefault();{
              return false;
            }}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.phone),expression:"filters.phone"}],staticClass:"formedituser",attrs:{"type":"text","id":"beneficiaryMobilePhone"},domProps:{"value":(_vm.filters.phone)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBeneficiaries()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "phone", $event.target.value)}}})])])]),_c('tr',[_c('td',[_c('a',{staticClass:"notification-action custom-notification-action",on:{"click":function($event){return _vm.searchBeneficiaries()}}},[_vm._v(_vm._s(_vm.$t("searchButtonLabel")))])])])])]),_c('div',{staticClass:"container-alb"},[_c('br'),_c('BeneficiariesTable',{attrs:{"beneficiaries":this.beneficiariesList},on:{"change":function($event){return _vm.sortBy(_vm.sortField, _vm.sortDirection)}}}),(this.beneficiariesList && this.beneficiariesList.length === 0)?_c('div',{staticClass:"error-wrapper border-box"},[_c('p',[_vm._v(_vm._s(_vm.$t("noBeneficiariesInfoMessage")))])]):_vm._e(),(this.transactionError)?_c('div',{staticClass:"error-wrapper border-box"},[_c('p',[_vm._v(_vm._s(_vm.$t("errorTransactionMessage")))])]):_vm._e(),_c('div',{staticClass:"pagination-wrapper border-box"},[_c('b-pagination',{attrs:{"total-rows":this.beneficiariesCount,"per-page":_vm.perPage,"size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('div',{staticClass:"modal-popup",attrs:{"id":"modal_popup_info"}},[_c('div',{staticClass:"modal-popup-content"},[_c('a',{staticClass:"close-button",on:{"click":function($event){return _vm.showModalPopup('modal_popup_info', false)}}},[_c('img',{attrs:{"src":require("@/assets/images/close-button.svg")}})]),(this.error)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('errorActionBeneficiary_' + this.error.code))}})]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }