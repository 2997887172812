<template>
  <div>
    <div>
      <div class="menu">
        <a href="/company" class="logo cursor-pointer">
          <img src="@/assets/images/logo-up.png" />
        </a>
        <!-- :contractStatus="contractStatus" -->
        <MenuItems
          :company="this.company"
          :menuSelected="this.menuSelected"
          :user="this.user"
        />
      </div>

      <div class="menu-mobile">
        <div class="user-mobile">
          <div class="close-menu" @click="openMenu(false)">
            <img src="@/assets/images/x.svg" />
          </div>

          <div>
            <img src="@/assets/images/settings.png" />
          </div>
          <div>
            <a href="#">{{ this.company.name | titleCase }}</a>
          </div>
        </div>
        <!-- :contractStatus="contractStatus" -->
        <MenuItems
          :company="this.company"
          :menuSelected="this.menuSelected"
          :user="this.user"
        />
      </div>

      <div class="container-right">
        <div
          class="barTop"
          :class="menuSelected.dashboard ? 'view' : 'bar-top-white'"
        >
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="@/assets/images/logo-up.png" />
          </div>
          <div
            class="menu-white"
            v-if="menuSelected.dashboard"
            @click="openMenu(true)"
          >
            <img src="@/assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav" v-if="!menuSelected.dashboard">
            <div class="arrow-back" @click="goBack()">
              <img src="@/assets/images/arrow-back.svg" />
            </div>
            <div class="page-title">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="@/assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <TopBar :company="this.company" :user="this.user" />
        </div>

        <div
          class="content"
          :class="this.menuSelected.search && 'dashboard-beneficiary'"
        >
          <BeneficiarySearch v-if="this.menuSelected.search" />
          <BeneficiaryDetails v-if="this.menuSelected.beneficiary" />

          <!-- Flexben Menu items -->
          <FlexBenDashboard
            v-if="this.menuSelected.flexben"
            :customer="this.customer"
            :user="this.user"
          />
          <FlexBenConfiguration v-if="this.menuSelected.flexbenConfiguration" />
          <FlexbenAffiliationChannelsList v-if="this.menuSelected.flexbenChannelsList" />
          <FlexBenCategories v-if="this.menuSelected.flexbenCategories" />
          <FlexBenClients v-if="this.menuSelected.flexbenClients" />
          <FlexBenEditClient v-if="this.menuSelected.flexbenEditClient" />
          <FlexBenBenefits v-if="this.menuSelected.flexbenBenefits" />
          <FlexBenBeneficiaries v-if="this.menuSelected.flexbenBeneficiaries" />
          <FlexBenBeneficiary
            v-if="this.menuSelected.flexbenNewBeneficiary"
            :edit="false"
          />
          <FlexBenBeneficiary
            v-if="this.menuSelected.flexbenEditBeneficiary"
            :edit="true"
          />
          <FlexBenRequests v-if="this.menuSelected.flexbenRequests" />
          <FlexBenRequestsHistoryList
            v-if="this.menuSelected.flexbenRequestsHistoryList"
          />
          <FlexBenOrders v-if="this.menuSelected.flexbenOrders" />
          <FlexbenImportHistory v-if="this.menuSelected.flexbenImportHistory" />
          <FlexbenImportIndex v-if="this.menuSelected.flexbenImportNew" />
          <FlexbenBenefitGroupList
            v-if="this.menuSelected.flexbenBenefitGroupList"
          />
          <FlexbenFixedBenefitsList
            v-if="this.menuSelected.flexbenFixedBenefitsList"
          />
          <FlexbenOfferCategoryList
            v-if="this.menuSelected.flexbenOfferCategoryList"
          />
          <FlexbenOfferList v-if="this.menuSelected.flexbenOfferList" />
          <FlexbenPartnerList v-if="this.menuSelected.flexbenPartnerList" />
          <FlexbenBrandList v-if="this.menuSelected.flexbenBrandList" />
          <FlexBenReportTypes v-if="this.menuSelected.flexbenReportsList" />
          <FlexbenBenefitVoucherTabs
            v-if="this.menuSelected.flexbenBenefitVoucherTabs"
          />

          <Contact :customer="this.company" v-if="this.menuSelected.contact" />
          <CompanySettings
            :companyDetails="this.company"
            :user="this.user"
            :userRole="this.company.role"
            v-if="this.menuSelected.account"
          />
          
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment/src/moment";
  import Cookies from "js-cookie";
  import MenuItems from "@/components/company/beneficiaries-support/MenuItems.vue";
  import TopBar from "@/components/company/TopBar.vue";
  import BeneficiarySearch from "@/components/company/beneficiaries-support/BeneficiarySearch.vue";
  import BeneficiaryDetails from "@/components/company/beneficiaries-support/BeneficiaryDetails.vue";
  import CompanySettings from "@/components/company/beneficiaries-support/CompanySettings.vue";
  import Contact from "@/components/company/customer/Contact.vue";
  import FlexBenDashboard from "@/components/flexben/dashboard/Dashboard";
  import FlexBenConfiguration from "@/components/flexben/configuration/ConfigurationIndex";
  import FlexBenCategories from "@/components/flexben/categories/CategoriesIndex.vue";
  import FlexBenClients from "@/components/flexben/clients/ClientsIndex.vue";
  import FlexBenEditClient from "@/components/flexben/clients/EditClient.vue";
  import FlexBenBenefits from "@/components/flexben/benefits/BenefitsIndex.vue";
  import FlexBenBeneficiaries from "@/components/flexben/beneficiaries/BeneficiariesIndex.vue";
  import FlexBenBeneficiary from "@/components/flexben/beneficiaries/BeneficiaryAdministration.vue";
  import FlexBenRequests from "@/components/flexben/requests/RequestsIndex.vue";
  import FlexBenRequestsHistoryList from "@/components/flexben/requests/RequestsHistoryList.vue";
  import FlexBenOrders from "@/components/flexben/orders/OrdersIndex.vue";
  import FlexbenImportHistory from "@/components/flexben/import_history/ImportHistory.vue";
  import FlexbenImportIndex from "@/components/flexben/import/ImportIndex.vue";
  import FlexbenBenefitGroupList from "@/components/flexben/benefit_groups/BenefitGroupList.vue";
  import FlexbenFixedBenefitsList from "@/components/flexben/fixed_benefits/FixedBenefitsList.vue";
  import FlexbenOfferCategoryList from "@/components/flexben/offer_category/OfferCategoriesList.vue";
  import FlexbenOfferList from "@/components/flexben/offers/OfferList.vue";
  import FlexbenPartnerList from "@/components/flexben/partners/PartnerList.vue";
  import FlexbenBrandList from "@/components/flexben/brands/List.vue";
  import FlexBenReportTypes from "@/components/flexben/reports/ReportTypes.vue";
  import FlexbenBenefitVoucherTabs from "@/components/flexben/benefit_voucher_stocks/TabsComponent.vue";
  import FlexbenAffiliationChannelsList from "@/components/flexben/affiliate_partners/channels/ChannelsList.vue";
  import $ from "jquery";
  import httpService from "@/http/http-common.js";
  import httpFlexbenService from "@/http/http-flexben.js";

  export default {
    components: {
      MenuItems,
      TopBar,
      BeneficiarySearch,
      BeneficiaryDetails,
      Contact,
      CompanySettings,
      FlexBenDashboard,
      FlexBenConfiguration,
      FlexBenCategories,
      FlexBenClients,
      FlexBenEditClient,
      FlexBenBenefits,
      FlexBenBeneficiaries,
      FlexBenBeneficiary,
      FlexBenRequests,
      FlexBenOrders,
      FlexbenImportHistory,
      FlexbenImportIndex,
      FlexbenBenefitGroupList,
      FlexbenFixedBenefitsList,
      FlexbenOfferCategoryList,
      FlexbenOfferList,
      FlexbenPartnerList,
      FlexbenBrandList,
      FlexBenRequestsHistoryList,
      FlexBenReportTypes,
      FlexbenBenefitVoucherTabs,
      FlexbenAffiliationChannelsList,
    },
    props: {
      user: Object,
      company: Object
    },
    data() {
      return {
        userLink: "",
        token: "",
        flexben: {
          hasAccess: false
        },
        menuSelected: {
          search: false,
          beneficiary: false,
          account: false,
          flexben: false,
          flexbenConfiguration: false,
          flexbenClients: false,
          flexbenEditClient: false,
          flexbenBenefits: false,
          flexbenNewBeneift: false,
          flexbenEditBenefit: false,
          flexbenBenefitEditUpDejun: false,
          flexbenBenefitEditUpVacanta: false,
          flexbenBenefitEditUpCadou: false,
          flexbenBenefitEditUpCultura: false,
          flexbenBeneficiaries: false,
          flexbenNewBeneficiary: false,
          flexbenEditBeneficiary: false,
          flexbenRequests: false,
          flexbenRequestsHistoryList: false,
          flexbenOrders: false,
          flexbenImportHistory: false,
          flexbenImportNew: false,
          flexbenBenefitGroupList: false,
          flexbenFixedBenefitsList: false,
          flexbenOfferCategoryList: false,
          flexbenOfferList: false,
          flexbenPartnerList: false,
          flexbenBrandList: false,
          flexbenReportsList: false,
          flexbenTerms: false,
          flexbenFaq: false,
          flexbenBenefitVoucherTabs: false,
          flexbenChannelsList: false,
        },

        isOpen: false,
        config: JSON.parse(sessionStorage.getItem("client_jsonFile"))
      };
    },
    methods: {
      openMenu(open) {
        if (open) {
          $(".menu-mobile").show();
        } else {
          $(".menu-mobile").hide();
        }
      },
      openUserMenu() {
        this.isOpen = !this.isOpen;
      },
      hide() {
        this.isOpen = false;
      },
      hideNotification() {
        this.isOpenNotification = false;
      },
      getPageTitle() {
        if (this.menuSelected.search) {
          return "Search Beneficiary";
        } else if (this.menuSelected.beneficiary) {
          return "Beneficiar";
        } else if (this.menuSelected.contact) {
          return "Contact";
        } else {
          return "Home";
        }
      },
      goBack() {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      },
      getExpireAmountDateFormat(date) {
        return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
      },
      flexbenSyncUser() {
        this.flexben.hasAccess = true;
        let syncData = {
          auth_token: this.token,
          link: this.userLink,
          role: this.flxUserLevel().userLevel,
          company: {
            company_id: 0,
            role: "FAC"
          },
        };
        httpFlexbenService.userService
          .syncUserData(syncData)
          .then((response) => {
            if (
              this.flxUserLevel().userLevel === "client" &&
              response.data.employments.length > 0
            ) {
              sessionStorage.setItem(
                "flx_active_employments",
                JSON.stringify(response.data.employments)
              );
              sessionStorage.setItem(
                "flx_active_employment",
                JSON.stringify(response.data.employments[0])
              );
            }

            if (this.$refs['menu_items']) {
              this.$refs['menu_items'].renderFlxMenu(this.flexben.hasAccess);
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("error multiben sync");
            Cookies.remove("flxCookie");
          });
      },
    },
    mounted() {
      // prevent click outside event with popupItem.
      if (!sessionStorage.getItem("client_jsonFile")) {
        httpService.commonsService.getConfigFile(localStorage.language, "client");
      }
      let permission = this.flxUserLevel();
      this.flexben.hasAccess = permission.hasAccess;
      if (permission.hasAccess && permission.userLevel === "admin") {
        this.flexbenSyncUser();
      }
    },
    created() {
      switch (this.$route.name) {
        case "support_beneficiary":
          this.menuSelected.beneficiary = true;
          break;
        case "support_account":
          this.menuSelected.account = true;
          break;
        case "support_beneficiaries_search":
          this.menuSelected.search = true;
          break;
        case "flexben":
          this.menuSelected.flexben = true;
          break;
        case "flexben_configuration":
          this.menuSelected.flexbenConfiguration = true;
          break;
        case "flexben_categories":
          this.menuSelected.flexbenCategories = true;
          break;
        case "up_mobil_plus_categories":
          this.menuSelected.superUpCategoriesList = true;
          break;
        case "up_mobil_plus_labels":
          this.menuSelected.superUpLabelsList = true;
          break;
        case "flexben_client":
          this.menuSelected.flexbenClient = true;
          break;
        case "flexben_clients":
          this.menuSelected.flexbenClients = true;
          break;
        case "flexben_edit_client":
          this.menuSelected.flexbenEditClient = true;
          break;
        case "flexben_benefits":
          this.menuSelected.flexbenBenefits = true;
          break;
        case "flexben_beneficiaries":
          this.menuSelected.flexbenBeneficiaries = true;
          break;
        case "flexben_beneficiary_new":
          this.menuSelected.flexbenNewBeneficiary = true;
          break;
        case "flexben_beneficiary_edit":
          this.menuSelected.flexbenEditBeneficiary = true;
          break;
        case "flexben_requests":
          this.menuSelected.flexbenRequests = true;
          break;
        case "flexben_requests_history_list":
          this.menuSelected.flexbenRequestsHistoryList = true;
          break;
        case "flexben_orders":
          this.menuSelected.flexbenOrders = true;
          break;
        case "flexben_import_history":
          this.menuSelected.flexbenImportHistory = true;
          break;
        case "flexben_import_new":
          this.menuSelected.flexbenImportNew = true;
          break;
        case "flexben_benefit_group_list":
          this.menuSelected.flexbenBenefitGroupList = true;
          break;
        case "flexben_fixed_benefits_list":
          this.menuSelected.flexbenFixedBenefitsList = true;
          break;
        case "flexben_offer_category_list":
          this.menuSelected.flexbenOfferCategoryList = true;
          break;
        case "flexben_offer_list":
          this.menuSelected.flexbenOfferList = true;
          break;
        case "flexben_partner_list":
          this.menuSelected.flexbenPartnerList = true;
          break;
        case "flexben_brand_list":
          this.menuSelected.flexbenBrandList = true;
          break;
        case "flexben_report_list":
          this.menuSelected.flexbenReportsList = true;
          break;
        case "flexben_faq":
          this.menuSelected.flexbenFaq = true;
          break;
        case "flexben_terms":
          this.menuSelected.flexbenTerms = true;
          break;
        case "flexben_benefit_voucher_list":
          this.menuSelected.flexbenBenefitVoucherTabs = true;
          break;
        case "flexben_channels_list":
          this.menuSelected.flexbenChannelsList = true;
          break;
        default:
          this.menuSelected.search = true;
      }
    }
  };
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
