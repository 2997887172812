<template>
  <div>
    <div class="container-alb filter-mobile">
      <table
        cellpadding="10"
        cellspacing="0"
        width="95%"
        class="hover-table"
        align="center"
      >
        <tr>
          <!-- <td>
            {{ $t("cardTransactionFilterProduct") }}
            <select class="filtrare" @change="productSelected">
              <option
                v-for="(entry, index) in cardProducts"
                :key="index"
                :value="entry.card"
                :selected="isSelectedProduct(entry.card)"
              >
                {{ entry.name }}
              </option>
            </select>
          </td> -->
          <td>
            {{ $t("beneficiariesTableCNP") }}
            <form
              @submit.prevent="
                {
                return false;
              }
                "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.cnp"
                v-on:keyup.enter="searchBeneficiaries()"
              />
            </form>
          </td>
          <td>
            {{ $t("lastNameLabel") }}
            <form
              @submit.prevent="
                {
                return false;
              }
                "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.last_name"
                v-on:keyup.enter="searchBeneficiaries()"
              />
            </form>
          </td>
          <td>
            {{ $t("firstNameLabel") }}
            <form
              @submit.prevent="
                {
                return false;
              }
                "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.first_name"
                v-on:keyup.enter="searchBeneficiaries()"
              />
            </form>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("emailField") }}
            <form
              @submit.prevent="
                {
                return false;
              }
                "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.email"
                v-on:keyup.enter="searchBeneficiaries()"
              />
            </form>
          </td>
          <td>
            {{ $t("mobileField") }}
            <form
              @submit.prevent="
                {
                return false;
              }
                "
            >
            <input
                  type="text"
                  id="beneficiaryMobilePhone"
                  v-model="filters.phone"
                  class="formedituser"
                  v-on:keyup.enter="searchBeneficiaries()"
                  />
            </form>
          </td>
          <!-- <td colspan="2">
            {{ $t("usernameLabel") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.username"
                v-on:keyup.enter="searchBeneficiaries()"
              />
            </form>
          </td> -->
        </tr>
        <tr>
          <td>
            <a
                  class="notification-action custom-notification-action"
                  @click="searchBeneficiaries()"
                  >{{ $t("searchButtonLabel") }}</a
                >
          </td>
        </tr>
      </table>
    </div>

    <!-- card transaction -->
    <div class="container-alb">
      <!-- <div class="table-header-wrapper">
        <div class="left">
          <h3 class="table-title d-inline-block">
            {{ $t("beneficiariesName") }}
          </h3>
          <!-- <img
            class="download d-inline-block"
            @click="getExcelTransaction()"
            src="@/assets/images/download.svg"
          /> --
        </div>
      </div> -->
      <br />

      <BeneficiariesTable
        :beneficiaries="this.beneficiariesList"
        @change="sortBy(sortField, sortDirection)"
      />

      <div
        class="error-wrapper border-box"
        v-if="this.beneficiariesList && this.beneficiariesList.length === 0"
      >
        <p>{{ $t("noBeneficiariesInfoMessage") }}</p>
      </div>

      <div class="error-wrapper border-box" v-if="this.transactionError">
        <p>{{ $t("errorTransactionMessage") }}</p>
      </div>

      <div class="pagination-wrapper border-box">
        <b-pagination
          v-model="currentPage"
          :total-rows="this.beneficiariesCount"
          :per-page="perPage"
          size="sm"
        ></b-pagination>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.error">
          <p v-html="$t('errorActionBeneficiary_' + this.error.code)"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BeneficiariesTable from "@/components/company/beneficiaries-support/BeneficiariesTable.vue";
  import utils from "@/plugins/utils.js";

  import httpServiceBenef from "@/http/http-ben-support.js";
  import { BPagination } from "bootstrap-vue";
  import Cookies from "js-cookie";
  import { saveAs } from "file-saver";
  import validator from "@/plugins/fieldValidation.js";

  export default {
    props: {
      msg: String,
    },
    components: {
      BeneficiariesTable,
      "b-pagination": BPagination,
      // Multiselect
    },
    data() {
      return {
        transactionError: null,
        beneficiariesList: [],
        tempInvoices: null,
        filters: {
          // product: 11,
          cnp: "",
          email: "",
          phone: "",
          username: "",
          first_name: "",
          last_name: "",
        },
        beneficiariesCount: 10,
        perPage: 10,
        currentPage: 1,
        sortField: "name",
        sortDirection: "asc",
        selectedProduct: null,
        error: null,
        config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
        // cardProducts: [
        //   {
        //     brand: "dejun",
        //     card: 11,
        //     name: "Up Dejun",
        //     order: 1,
        //     paper: 0,
        //     perso: true,
        //   },
        //   {
        //     brand: "cadou",
        //     card: 13,
        //     name: "Up Cadou",
        //     order: 2,
        //     paper: 0,
        //     perso: true,
        //   },
        // ],
      };
    },
    methods: {
      productSelected(event) {
        this.filters.product = event.target.value;
        this.searchBeneficiaries();
      },
      isSelectedProduct(product) {
        return product === this.filters.product;
      },
      sortBy(sortField, sortDirection, status) {
        this.sortField = sortField;
        this.sortDirection = sortDirection;
        this.searchBeneficiaries(status);
      },
      showModalPopup(elementId, show) {
        if (show) {
          document.getElementById(elementId).style.visibility = "visible";
        } else {
          document.getElementById(elementId).style.visibility = "hidden";
        }
      },
      searchBeneficiaries() {
        this.error = null;
        if (
          this.filters.email !== null &&
          this.filters.email.trim().length > 0 &&
          !validator.validateEmail(this.filters.email)
        ) {
          this.error = { code: "EMAIL_INVALID" };
          this.showModalPopup("modal_popup_info", true);
        } else
          if (
            this.filters.phone !== null &&
            this.filters.phone.length > 0 &&
            !validator.validateMobilePhone(this.filters.phone)
          ) {
            this.error = { code: "MOBILE_INVALID" };
          } else
            if (this.filters.cnp != null && this.filters.cnp.length > 0 && this.filters.cnp.length !== 13) {
              this.error = { code: "CNP_INVALID" };
            }
        if (this.error != null) {
          this.showModalPopup("modal_popup_info", true);
          return;
        }
        // this.beneficiariesList = [{
        //   cnp: '2881201465588',
        //   email: 'acioc@upromania.ro',
        //   phone: '0547896554',
        //   first_name: 'Andreea',
        //   last_name: 'Cioc',
        //   id: 2345
        // }];
        // this.beneficiariesCount = 10;
        // return;
        var beneficiariesFilter = {
          per_page: this.perPage,
          page: this.currentPage,
          sort: this.sortDirection
        }
        // "?product=" +
        // this.filters.product +
        if (this.filters.cnp.trim().length > 0) {
          beneficiariesFilter.cnp = this.filters.cnp;
        }
        if (this.filters.email.trim().length > 0) {
          beneficiariesFilter.email = this.filters.email;
        }
        if (this.filters.phone.trim().length > 0) {
          beneficiariesFilter.phone = this.filters.phone;
        }
        if (this.filters.first_name.trim().length > 0) {
          beneficiariesFilter.first_name = this.filters.first_name;
        }
        if (this.filters.last_name.trim().length > 0) {
          beneficiariesFilter.last_name = this.filters.last_name;
        }
        // (this.filters.username.trim().length > 0
        //   ? "&username=" + this.filters.username
        //   : "") +
        if (this.sortDirection === "desc") {
          beneficiariesFilter.desc = [this.sortField];
        } else {
          beneficiariesFilter.asc = [this.sortField];
        }
        this.showModalPopup("modal_popup_loading", true);

        httpServiceBenef.beneficiariesService
          .searchBeneficiaries(beneficiariesFilter, Cookies.get("token"))
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            return error.response;
          })
          .then((response) => {
            
            if (response.status == 200) {
              this.beneficiariesList = response.data;
              this.beneficiariesCount = response.headers["x-up-count"];
            } else if (response.status == 400) {
              this.error = { code: response.data.error_code };
            } else {
              this.error = { code: response.error_code };
            }
            if (this.error) {
              
              this.showModalPopup("modal_popup_info", true);
            }
            this.showModalPopup("modal_popup_loading", false);
          })
          
      },
      selectProduct(index) {
        this.selectedProduct = this.cardProducts[index];
      },
      getExcelTransaction() {
        this.showModalPopup("modal_popup_loading", true);

        var beneficiariesFilter =
          "?product=" +
          this.filters.product +
          (this.filters.cnp.trim().length > 0
            ? "&cnp=" + this.filters.cnp
            : "") +
          (this.filters.affiliate_name.trim().length > 0
            ? "&name=" + this.filters.affiliate_name
            : "") +
          (this.filters.username.trim().length > 0
            ? "&username=" + this.filters.username
            : "") +
          (this.filters.first_name.trim().length > 0
            ? "&first_name=" + this.filters.first_name
            : "") +
          (this.filters.last_name.trim().length > 0
            ? "&last_name=" + this.filters.last_name
            : "");
        beneficiariesFilter +=
          "&per_page=" + 100000 + "&page=" + 1 + "&sort=" + this.sortDirection;
        if (this.sortDirection === "desc") {
          beneficiariesFilter += "&desc=" + this.sortField;
        } else {
          beneficiariesFilter += "&asc=" + this.sortField;
        }

        return httpServiceBenef.beneficiariesService
          .getTransactionFile(beneficiariesFilter, Cookies.get("token"))
          .then((response) => {
            if (response.status === 200) {
              var blob = new Blob(
                [utils.base64ToArrayBuffer(response.data.content)],
                {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel",
                }
              );
              saveAs(blob, response.data.name);
            } else if (response.status === 400 || response.status === 403) {
              this.transactionError = { code: 500 };
            } else {
              this.transactionError = { code: 500 };
            }
            this.showModalPopup("modal_popup_loading", false);
          })
          .catch((error) => {
            this.transactionError = { code: 500 };
            this.showModalPopup("modal_popup_loading", false);
          });
      },
    },
    // computed: {
    //   cardProducts() {
    //     let cardProducts = [];
    //     for (let index in this.config.products) {
    //       if (this.config.products[index].card > 0) {
    //         cardProducts.push(this.config.products[index]);
    //       }
    //     }
    //     return cardProducts;
    //   },
    // },
    mounted() {
      Cookies.remove("selectedBeneficiary");
      // this.filters.product = this.config.products[0].card;
      // this.beneficiariesList = this.searchBeneficiaries();
    },
    created() {
    },
    watch: {
      currentPage: function () {
        this.searchBeneficiaries();
      },
    },
  };
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
  .page-link {
    color: #4a4a4a;
  }

  .page-link:hover {
    color: #4e5b59;
  }

  .page-item.active .page-link {
    background-color: #4e5b59 !important;
    border-color: #4e5b59 !important;
  }

  .invoices-filter .reportrange-text {
    height: 27px;
    font-size: 13px;
    padding: 3px 10px;
    border: none !important;
    color: #585858;
    background: #f1f1f1;
  }
</style>
